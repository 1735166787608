
import { Component, Vue } from "vue-property-decorator";

import EquipmentBrandsTable from "@/components/Core/Table/Equipments/EquipmentBrandsTable.vue";

@Component({
  components: {
    EquipmentBrandsTable,
  },
})
export default class EquipmentBrands extends Vue {}
