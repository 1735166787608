import { render, staticRenderFns } from "./EquipmentBrandsTable.vue?vue&type=template&id=35c1e7ba"
import script from "./EquipmentBrandsTable.vue?vue&type=script&lang=ts"
export * from "./EquipmentBrandsTable.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports