
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { ServerType } from "prometheus-synced-ui";
import {
  EquipmentBrandDetails,
  EquipmentBrandInfos,
  LITE_FEATURE,
} from "@/domain";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { IEquipmentBrandService } from "@/services";

@Component({
  components: {
    TableUi,
  },
})
export default class EquipmentBrandsTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get equipmentBrandService() {
    return this.container.resolve<IEquipmentBrandService>(
      S.EQUIPMENT_BRAND_SERVICE
    );
  }

  get existingBrandNames() {
    return _.map(this.equipmentBrands, (e) => e.name);
  }

  get headers() {
    return [
      {
        text: "Nom de la marque",
        align: "center",
        sortable: true,
        value: "name",
      },
      {
        text: "Actions",
        sortable: false,
        value: "actions",
        align: "center",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  get createComponent() {
    return () =>
      import("@/components/Core/Create/Equipments/EquipmentBrandCreate.vue");
  }

  get updateComponent() {
    return () =>
      import("@/components/Core/Update/Equipments/EquipmentBrandUpdate.vue");
  }

  equipmentBrands: EquipmentBrandInfos[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    this.equipmentBrands = await this.equipmentBrandService.getMany({
      ids: [],
      includeDisabled: true,
      includeEnabled: true,
    });
  }

  create(createdBrand: EquipmentBrandInfos) {
    this.equipmentBrands.push({
      id: createdBrand.id,
      name: createdBrand.name,
      disabled: createdBrand.disabled,
    });
  }

  update(updatedBrand: EquipmentBrandDetails) {
    const index = _.findIndex(
      this.equipmentBrands,
      (s) => s.id == updatedBrand.id
    );
    if (index != -1) {
      this.equipmentBrands.splice(index, 1, {
        id: updatedBrand.id,
        name: updatedBrand.name,
        disabled: updatedBrand.disabled,
      });
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const brand = _.find(this.equipmentBrands, (b) => b.id == id)!;
      const result = await this.equipmentBrandService.update(id, {
        name: brand.name!,
        disabled: !brand.disabled,
      });
      this.update(result);
    } finally {
      this.loading = false;
    }
  }
}
